.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: $h1;
}
.h2 {
  font-size: $h2;
}

.color {
  &-primary {
    color: color(primary, base);
  }
  &-gray {
    &1 {
      color: color(gray, one);
    }
    &2 {
      color: color(gray, two);
    }
    &3 {
      color: color(gray, three);
    }
    &4 {
      color: color(gray, four);
    }
  }
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: color(primary, base) !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: color(primary, base) !important;
  border-color: color(primary, base) !important;
}

//modal body here

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: $text-xlg;
  &-title {
    text-align: center;
    margin-bottom: 1rem;
  }
}

//tabs navigation

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: color(text-light);
  width: 12rem !important;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: color(primary, base) !important;
  background: white !important;

  font-weight: 600;
  border-bottom: 2px solid;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: color(text-light) !important;
  cursor: pointer;
}

//cursor pointer

.cpntr {
  cursor: pointer;
}

a {
  // color: color(primary, base) !important   ;
}

.whattsapp {
  background: #43c71b;
  font-size: 2rem;
  height: 4rem;
  width: 4rem;
  border-radius: calc(4rem);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 9;
  box-shadow: 1px 4px 16px #a9a9a9;
  @include responsive-for(sm) {
    font-size: 1.5rem;
    height: 3rem;
    width: 3rem;
    bottom: 15%;
  }

  &:hover {
    color: #43c71b;
    background: white;
    border: 1px solid green;
    transition: all 0.2s ease-in;
  }
}
