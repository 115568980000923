.accountWrapper {
  margin-top: 2rem;

  .accountTabHolder {
    &-items {
      padding: 0.5rem;

      .acc-lgout,
      a {
        cursor: pointer;
        text-decoration: none;
        color: color(text-dark);
        padding: 0.5rem 1rem;
        border-radius: $radius;
        width: 100%;
        display: inline-block;
        &:hover {
          background-color: color(text-extra-light);
        }

        .review-icon {
          font-size: 14px;
        }
      }
    }
  }
}

.orderWrapper {
  border-bottom: 1px solid color(text-extra-light);
  padding: 0.5rem;
  margin-top: 2rem;
  thead {
    background: white;
    color: color(text-dark);
  }
  th {
    font-weight: 600 !important;
    border-width: 0px !important;
  }
  .imageHolder {
    display: flex;
    align-items: center;

    figure {
      margin: 0;
      img {
        height: 2.5rem;
        width: 2.5rem;
        object-fit: contain;
        margin-right: 1rem;
        border: $border;
      }
    }

    &-text {
      font-size: 1.1rem;
      @include responsive-for(sm) {
        font-size: 14px;
      }
    }
  }
}

.detailsWrapper {
  width: 50%;
  @include responsive-for(md) {
    width: 100%;
  }
}

.guestRegister {
  @include responsive-for(sm) {
    display: none;
  }
}

.deleteHolder {
  background: #ffe9e9;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: $radius;
  &-box {
    &-cross {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      cursor: pointer;
      i {
        font-size: 1.3rem;
        transition: all 0.2s ease;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
    &-title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 1rem;
      text-align: center;
    }
    &-desc {
      font-size: 0.9rem;
      font-weight: 300;
      margin-top: 1rem;
    }

    label {
      font-weight: 500;
    }

    input {
      width: 100%;
      padding: 0.6rem;
      border-radius: $radius;
      border: $border;
      color: #414141;
      letter-spacing: 1px;
      margin-top: 0.5rem;
    }

    &-err {
      width: 100%;
      font-weight: 500;
      color: rgb(228, 9, 9);
      margin-top: 0.5rem;
      font-size: 13px;
    }
  }
}

//login & signup
.LoginTabs {
  text-align: center;
  button {
    background-color: white;
    border: none;
    font-size: 2.5rem;
    font-weight: 600;
    color: color(text-light);
  }
  :nth-child(1) {
    margin-right: 1rem;
  }

  .inactiveLogin {
    color: #a4a4a4;
  }
}
