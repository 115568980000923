.layoutTitle {
  margin-top: 5rem;
  margin-bottom: 3rem;
  text-align: center;
  h1 {
    font-weight: 700 !important;
  }
}

.productCard {
  position: relative;
  margin-bottom: 1rem;
  border-radius: $radius;
  padding: 1rem;
  background: color(background-color);
  &-image {
    height: 18rem;
    // padding: 1.5rem;
    @include responsive-for(sm) {
      height: 8rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    color: color(text-dark);
    font-weight: 400 !important;
    letter-spacing: 0.5px;
    height: 2.5rem;
    overflow: hidden;
    font-size: 15px !important;
    @include truncate;
    -webkit-line-clamp: 2;
  }
  &-price {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .priceValue {
      font-weight: 500;
      font-size: 16px;
      color: color(primary, base);
    }
    .stockValue {
      color: color(success, base);
      background: color(success, tint);
      font-size: 10px;
      font-weight: 400;
      padding: 4px 6px;
      border-radius: 2px;
      position: absolute;
      bottom: 6.3rem;
      right: 1rem;
    }
    .noStock {
      color: color(danger, base);
      background: color(danger, tint);
      font-size: 10px;
      font-weight: 400;
      padding: 4px 6px;
      border-radius: 2px;
      position: absolute;
      bottom: 6.3rem;
      right: 1rem;
    }
  }

  .cardOff {
    background: color(primary, base);
    position: absolute;
    top: 5%;
    left: 5%;
    padding: 0.1rem;
    font-size: 12px;
    color: white;
  }
}

.adsWrapper {
  margin: 5rem 0;
  &-image {
    img {
      width: 100%;
    }
  }
}

//clean

.homeCleanWrapper {
  margin-top: 5rem;
  &-title {
    margin-bottom: 2rem;
  }
  &-box {
    width: 80%;
    text-align: justify;
    @include responsive-for(sm) {
      width: 100%;
    }
  }
  &-image {
    img {
      width: 100%;
    }
  }
}

.myOrderTable {
  @include responsive-for(md) {
    display: none;
  }
}

.loginError {
  background: color(danger, tint);
  margin-top: 1rem;
  padding: 5px 1rem;
  border-radius: $radius;
  color: color(danger, base);
  width: 100%;
  text-align: center;
}

.socialLogin {
  display: flex;
  @include responsive-for(md) {
    display: block;
  }
}

//Navigarion Bar
.toggleWrapper {
  position: relative;
  .categoryToggle {
    padding: 1rem;
    border-radius: 4px;
    background: #fafafa;
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
    &-icon {
      font-size: 1.2rem;
      margin-right: 0.5rem;
    }
    .categoryIcon {
      color: color(text-light);
    }
    &-text {
      font-size: 1rem;
      color: color(text-light);
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .navCategories {
    position: absolute;
    background: #fff;
    box-shadow: $shadow;
    // margin: 0;
    padding: 0;
    width: 200px;
    text-align: left;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 500;
      font-size: 1rem !important;
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: color(text-dark);
      border-bottom: 1px solid rgb(235, 235, 235);
      &:hover {
        background: #f0f0f0;
      }
      img {
        width: 5rem;
        height: 3rem;
        object-fit: contain;
      }
    }
    &-subCategoriesBox {
      position: absolute;
      background: #fff;
      box-shadow: $shadow;
      left: 200px;
      top: 0%;
      width: 50vw;
      display: flex;
      flex-wrap: wrap;

      &-subCatItems {
        padding: 1rem;
        width: 33%;

        .subCatTitle {
          font-size: 1rem !important;
          font-weight: 600 !important;
          color: color(text-dark) !important;
        }
        .subCatItemBox {
          padding: 0;
          margin: 0;

          &-item {
            padding-bottom: 0.5rem;
            cursor: pointer;
            font-size: 1rem;
            color: color(text-light);
            font-size: 14px;
            span {
              color: color(text-dark) !important;
            }
          }
        }
      }
    }
  }
}

//testimonial

.testimonialWrapper {
  background: color(background-color);
  padding: 2rem 1rem;

  .testimonialBox {
    background-color: color(white);
    display: flex;
    padding: 0.5rem;
    border-radius: $radius;

    .testiDesc {
      width: 75%;
      &-quote {
        margin-top: 1rem;

        &-text {
          margin-top: 0.5rem;
        }
      }
      &-person {
        font-weight: 600;
        font-size: 17px;
      }
      &-designation {
        font-weight: 400;
        font-size: 15px;
        color: color(text-light);
      }
    }

    .testiImage {
      width: 25%;
      margin-bottom: 0;
      img {
        width: 100%;
        min-height: 100%;
        offset: cover;
      }
    }
  }
}

.attractionWrapper {
  background: #f8f8f8;
  margin-top: 5rem;
  .atttractionsBox {
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    &-item {
      padding: 1rem 0;
      img {
        background-color: color(primary, base);
        height: 5rem;
        width: 5rem;
        border-radius: 100%;
      }
      figcaption {
        margin-top: 1rem;
      }
    }
  }
}
