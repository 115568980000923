.reviews {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include responsive-for(sm) {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
        }

        .rheader--text {
            font-size: 1.75rem !important;
            font-weight: 500 !important;
            color: color(text-dark) !important;
            margin: 0 !important;
        }
    }

    &-summary {
        border: 1px solid color(gray, three);
        border-radius: 6px;
        padding: 1rem;

        .rating-summary {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &_item {
                display: flex;
                flex-direction: row;
                gap: 0.75rem;
                align-items: center;
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0;
                margin: 0;

                &--star {
                    display: flex;
                    flex-direction: row;
                    // align-items: center;
                    // gap: 0.2rem;
                    flex: none;

                    .text {
                        width: 1.13288rem;
                        flex-shrink: 0;
                    }

                    .icon {
                        font-size: 1.3rem;
                        color: color(gray, three);
                        transition: color 150ms;
                    }

                    .selected {
                        color: #ffcd29;
                    }
                }

                &:hover {
                    .icon {
                        color: #ffcd29;
                    }
                }

                &--bar {
                    max-width: 31rem;
                    flex: 1 1 0%;
                    height: 0.45313rem;
                    border-radius: 0.45313rem;
                    background: color(gray, three);
                    .bar--main {
                        border-radius: inherit;
                        height: 100%;
                        background: #ffcd29;
                    }
                }

                &--per {
                    flex: none;
                    color: rgba(0, 0, 0, 0.5);
                    width: 2.5rem;
                }
            }
        }

        &_total {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2.72rem;
            min-height: 15rem;
            gap: 1rem;

            .total-stars {
                display: flex;
                flex-direction: row;
                gap: 1.25rem;
                justify-content: center;
                // align-items: center;
                padding: 1.5rem 2rem;
                background-color: color(primary, base);
                border-radius: 0.94738rem;

                &_text {
                    color: color(white);
                    font-size: 2rem;
                    font-weight: 700;
                }

                &_icon {
                    color: color(white);
                    font-size: 2.5rem;
                }
            }

            .total_reviews {
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }

    &-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include responsive-for(sm) {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
        }

        &_text {
            color: color(text-dark);
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;
        }

        &--sort {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;

            &_icon {
                // color: color(gray, three);
                font-size: 1.3rem;
            }

            &_select {
                color: color(text-light);
                font-size: 0.9rem;
                height: max-content;
                border: 1px solid color(gray, one);
                padding: 0.25rem 0.5rem;
                border-radius: 6px;
                outline: none;
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &-card {
        // padding: 0 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            &--date {
                color: rgba(51, 51, 51, 0.7);
                font-size: 0.9rem;
                // font-weight: 300;
                line-height: 1.5rem;
            }
        }

        &_author {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;

            .author_name {
                color: #808080;
                font-size: 0.9rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
            }

            .verified_badge {
                display: flex;
                flex-direction: row;
                gap: 0.2rem;
                color: #808080;
                align-items: center;

                &--text {
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                }
            }
        }

        &_text {
            color: color(text-dark);
            font-style: normal;
            line-height: 1.5rem;
            max-width: 90%;
            margin: 0 !important;
            padding: 0 !important;

            @include responsive-for(sm) {
                max-width: 100%;
            }
        }

        &_images {
            display: flex;
            flex-direction: row;
            gap: 0.75rem;
            flex-wrap: wrap;

            &--item {
                width: 7.75rem;
                height: 5.625rem;
                border-radius: 0.375rem;
                background: color(gray, three);
                object-fit: contain;
            }
        }
    }
}

.star-rating {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;

    &_icon {
        color: #f6b400;
    }
}

.mreviews {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &-header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &-tabs {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-wrap: wrap;

        &_item {
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            padding: 0.25rem 0;
            position: relative;
        }

        &_active {
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: color(primary, base);
                border-radius: 2px;
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding: 0.5rem 1rem;
            border: 1px solid color(gray, four);
            border-radius: 0.375rem;
            background-color: transparent;
            outline: none;
            text-align: left;

            @include responsive-for(sm) {
                flex-direction: column;
                align-items: flex-start;
            }

            &:hover {
                border-color: color(primary, base);
            }

            .mr-image {
                height: 5.5rem;
                width: 5.5rem;

                &_item {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 0.375rem;
                }
            }

            .mr-det {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;

                &_title {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem;
                    color: color(text-light);
                }

                &_cat {
                    font-size: 0.85rem;
                    font-style: normal;
                    font-weight: 400;
                    color: color(text-extra-light);
                }

                &_date {
                    font-size: 0.85rem;
                    font-style: normal;
                    font-weight: 300;
                    color: colot(text-extra-light);
                }
            }

            .mr-bton {
                margin-left: auto;
                padding: 0.5rem 1rem;
                background-color: color(primary, base);
                color: color(white);
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                border: none;
                outline: none;
                border-radius: 0.375rem;

                &:hover {
                    background-color: color(primary, hover);
                }

                @include responsive-for(sm) {
                    margin-left: 0;
                }
            }
        }

        &_empty {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            padding: 2rem;

            &--text {
                color: color(text-dark);
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
            }

            &--img {
                height: 10rem;
                width: auto;
                object-fit: contain;
            }

            &--link {
                color: color(primary, base);
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;

                &:hover {
                    color: color(primary, hover);
                }
            }
        }
    }

    &-history {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        &_item {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            padding: 1rem;
            border-radius: 0.375rem;

            @include responsive-for(sm) {
                gap: 0.8rem;
            }

            .mh-status {
                font-size: 0.9rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem;
            }

            .mh-product {
                display: flex;
                flex-direction: row;
                gap: 1.5rem;
                align-items: center;

                @include responsive-for(sm) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.5rem;
                }

                &_img {
                    height: 3.5rem;
                    width: 3.5rem;

                    &--item {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        border-radius: 0.375rem;
                    }
                }

                &-info {
                    display: flex;
                    flex-direction: column;
                    gap: 0.2rem;

                    &_title {
                        color: color(text-dark);
                        font-size: 1.125rem;
                        font-style: normal;
                        font-weight: 500;
                        // line-height: 1.375rem;
                    }

                    &_stars {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0.75rem;

                        @include responsive-for(sm) {
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 0.25rem;
                        }

                        &--dot {
                            color: color(text-extra-light);

                            @include responsive-for(sm) {
                                display: none;
                            }
                        }

                        &--date {
                            font-size: 0.8rem;
                            font-style: normal;
                            color: color(text-extra-light);
                        }
                    }
                }
            }

            .mh-review {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                &_text {
                    color: color(text-dark);
                    font-style: normal;
                    line-height: 1.5rem;
                    margin: 0 !important;
                    padding: 0 !important;
                }

                &_images {
                    display: flex;
                    flex-direction: row;
                    gap: 0.75rem;
                    flex-wrap: wrap;

                    &--item {
                        width: 7.75rem;
                        height: 5.625rem;
                        border-radius: 0.375rem;
                        background: color(gray, three);
                        object-fit: contain;
                    }
                }
            }

            .mh-rejected {
                display: flex;
                flex-direction: column;

                margin-top: 1.5rem;
                gap: 1rem;

                &_remarks {
                    display: flex;
                    flex-direction: column;
                    // gap: 0.25rem;

                    border: 1px solid color(gray, one);
                    padding: 0.5rem 1rem;
                    border-radius: 0.375rem;

                    &--title {
                        font-size: 0.875rem;
                        font-weight: 600;
                        color: color(text-dark);
                    }

                    &--text {
                        font-size: 0.8rem;
                        color: color(body-text);
                    }
                }

                &-bton {
                    align-self: flex-end;
                    padding: 0.375rem 0.75rem;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.25rem;
                    border-radius: 30rem;
                    background-color: transparent;

                    border: 1px solid color(primary, base);
                    color: color(primary, base);
                    transition: all 150ms;

                    &:hover {
                        background-color: color(primary, base);
                        color: color(white);
                    }
                }
            }
        }

        &-apr_bg {
            background-color: #f6fff6;
        }
        &-apr_text {
            color: #3cc13b;
        }

        &-pen_bg {
            background-color: #fff8f2;
        }
        &-pen_text {
            color: #fa7800;
        }

        &-rej_bg {
            background-color: #f1a6b426;
        }
        &-rej_text {
            color: #c9221a;
        }

        &_empty {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            padding: 4rem 0;

            &--text {
                color: color(text-light);
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
            }

            &--link {
                color: color(primary, base);
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;

                &:hover {
                    color: color(primary, hover);
                }
            }
        }
    }
}

.rmodal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    overflow-y: auto;

    &-bg {
        z-index: 1;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    &-main {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
        width: 100%;
        min-height: 100%;
    }

    &-dialog {
        z-index: 50;
        border-radius: 0.5rem;
        background-color: color(white);
        border: none;
        width: max-content;
        min-width: 48rem;
        max-width: 50rem;

        @include responsive-for(sm) {
            min-width: auto;
        }

        &_header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid color(gray, two);
            padding: 1rem 1.3rem;

            &--text {
                font-weight: 600;
                font-size: 1.25rem;
                color: color(text-dark);
            }

            &--bton {
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.25rem;
                color: color(text-light);
            }
        }
    }
    &-form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 2rem;

        &_item {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
        }

        &_label {
            color: color(body-text);
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
        }

        &_error {
            font-size: 0.8rem;
            color: color(danger, base);
        }
    }

    &-product {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        // padding: 0.5rem 1rem;
        text-align: left;

        @include responsive-for(sm) {
            flex-direction: column;
            align-items: flex-start;
        }

        .rmproduct-image {
            height: 5.5rem;
            width: 5.5rem;
            background-color: color(gray, four);
            border-radius: 0.375rem;

            &_item {
                height: 100%;
                width: 100%;
                object-fit: contain;
                border-radius: 0.375rem;
            }
        }

        .rmproduct-det {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            &_title {
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;
                color: color(text-light);
            }

            &_cat {
                font-size: 0.85rem;
                font-style: normal;
                font-weight: 400;
                color: color(text-extra-light);
            }

            &_date {
                font-size: 0.85rem;
                font-style: normal;
                font-weight: 300;
                color: colot(text-extra-light);
            }
        }
    }

    &-rating {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;

        &_label {
            cursor: pointer;
        }

        &_input {
            display: none;
        }

        &_icon {
            font-size: 1.35rem;
        }

        &_fill {
            color: #f6b400;
        }

        &_empty {
            color: color(gray, three);
        }
    }

    &-review {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        &_input {
            border-radius: 0.375rem;
            outline: none;
            border: 1px solid color(gray, four);
            padding: 0.5rem;
            resize: none;
            height: 8rem;
            font-size: 0.875rem;

            &:focus {
                border-color: color(gray, one);
            }
        }

        &_footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &_count {
            align-self: flex-end;
            text-align: right;
            font-size: 0.75rem;
            color: color(text-extra-light);
        }
    }

    &-images {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-wrap: wrap;

        &_item {
            height: 8rem;
            width: 8rem;
            background-color: color(gray, four);
            position: relative;

            &--img {
                height: 100%;
                width: 100%;
                border-radius: 0.375rem;
                object-fit: contain;
            }

            &--icon {
                position: absolute;
                top: 0;
                right: 0;
                color: color(danger, base);
                background-color: color(white);
                border-radius: 50%;
                height: 2rem;
                width: 2rem;
                outline: none;
                border: none;
                padding: 0;
                margin: 0;
            }
        }

        &_imgp {
            height: 8rem;
            width: 8rem;
            background-color: color(gray, four);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.375rem;

            &_icon {
                color: color(text-light);
            }
        }
    }

    &_spin {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    &-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
    }

    &_upload-bton {
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 500;
        font-size: 0.85rem;
        border-radius: 0.375rem;
        transition: all 150ms;
        padding: 0.5rem 0.75rem;
        color: color(body-text);
        cursor: pointer;

        &:hover {
            background-color: color(gray, four);
        }
    }

    &_submit-bton {
        background-color: color(primary, base);
        color: color(white);
        outline: none;
        border: none;
        font-weight: 500;
        font-size: 0.85rem;
        border-radius: 0.375rem;
        transition: all 150ms;
        padding: 0.5rem 2rem;
        min-width: 115px;

        &:hover {
            background-color: color(primary, hover);
        }
    }
}
